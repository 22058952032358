<template>
  <v-container>

    <!-- List of Stores -->
    <!-- <v-data-table mobile-breakpoint="200"
      hide-default-footer
      :headers="cols" :items="managers">
      
      <template v-slot:[`item.role`]="{ item }">
        {{ item.role_id | toRoleLiteral }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-btn x-small outlined fab color="blue-grey lighten-3" class="ma-2" @click="showDialogEditRole(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn x-small outlined fab color="blue-grey lighten-3" class="ma-2" @click="revokeStoreManager(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

    </v-data-table> -->

    <SSRTable ref="list-managers" :data="managers" :cols="cols">
      <div slot="role" slot-scope="{ item }">
        {{ item.role_id | toRoleLiteral }}
      </div>
      <div slot="actions" slot-scope="{ item }">
        <v-btn x-small outlined fab color="blue-grey lighten-3" class="ma-2" @click="showDialogEditRole(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn x-small outlined fab color="blue-grey lighten-3" class="ma-2" @click="revokeStoreManager(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </SSRTable>
    
    <!-- Footer - Button for adding a store manager -->
    <v-footer fixed style="background:transparent;">
      <v-btn large block rounded color="primary" @click="showDialogInvite">
        新增管理員
      </v-btn>
    </v-footer>


    <!-- Dialog - Edit Role -->
    <v-dialog v-model="dialog.edit.show">
      <v-card>
        <v-card-title>權限編輯</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="人員" v-model="dialog.edit.data.name"
                dense filled rounded prepend-inner-icon="mdi-account"
                required hide-details disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select dense filled rounded label="權限" hide-details
                :items="roleOptions"
                v-model="dialog.edit.data.role_id" prepend-inner-icon="mdi-card-account-details">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn large rounded block color="primary" @click="updateStoreManager">
            更新權限
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog - Invite URL -->
    <v-dialog v-model="dialog.invite.show">
      <v-card>
        <v-card-title>新增／邀請管理人員</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select dense filled rounded label="權限" hide-details
                :items="roleOptions"
                v-model="dialog.invite.data.roleId" prepend-inner-icon="mdi-card-account-details">
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-btn large block rounded color="primary" @click="requestInviationLink"
                :disabled="dialog.invite.data.inviteUrl.length > 0">
                產生邀請連結
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="dialog.invite.data.inviteUrl"
                dense filled rounded append-icon="mdi-content-copy"
                required hide-details
                @click:append="copyInvitationLink">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog - Revoke Confirmation -->
    <v-dialog v-model="dialog.revoke.show">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="d-flex flex-column">
          <CustomIcon :src="require('@/assets/icons/cross.svg')" class="mx-auto"></CustomIcon>
          <span class="mt-2 text-center text-subtitle-1">確定移除 {{ dialog.revoke.data.name }} 的權限？</span>
        </v-card-text>
        <v-card-actions>
          <v-btn large rounded block color="primary" @click="revokeStoreManager">確認</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable.vue';

// import axios from 'axios';
import SSORequest from '@/sso-request';

export default {
  components: {
    SSRTable
  },
  props: {
    storeId: String
  },

  mounted() {
    this.fetchStoreManagers();
  },

  methods: {

    fetchStoreManagers() {
      SSORequest.get(`${process.env.VUE_APP_TY_MANAGER_HOST}/stores/${this.storeId}/managers`)
      .then(response => {
        this.managers = response.data;
        setTimeout(() => {
          this.$refs['list-managers'].loadData();
        });
        
      })
      .catch(err => {
        console.error(`[STORE MANAGERS] fetchStoreManagers: error`, err);
      });
    },
    updateStoreManager() {
      let memberId = this.dialog.edit.data.id;
      let roleId = this.dialog.edit.data.role_id;
      SSORequest.put(
        `${process.env.VUE_APP_TY_MANAGER_HOST}/stores/${this.storeId}/managers/${memberId}`,
        { roleId }
      )
      .then(response => {
        //TODO: Show success alert
        this.fetchStoreManagers();
        this.$notify({
          type: 'success',
          text: `已更新 ${this.dialog.edit.data['name']} 的權限。`
        });
      })
      .catch(err => {
        console.error(`[STORE MANAGERS] updateStoreManager: error`, err);
      })
      .finally(() => {
        this.dialog.edit.show = false;
      });
      
    },
    requestInviationLink() {
      let roleId = this.dialog.invite.data.roleId;
      SSORequest.post(
        `${process.env.VUE_APP_TY_STORES_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${this.storeId}/issueInvitation-url`,
        { permissionRole: [ roleId ] }
      )
      .then(response => {
        //TODO: Fill the invitation link.
        console.log(`[STORE MANAGERS]<DEBUG> requestInviationLink: response.data`, response.data);
        this.dialog.invite.data.inviteUrl = response.data.url;
      })
      .catch(err => {
        console.error(`[STORE MANAGERS] requestInviationLink: error`, err);
      });
    },
    revokeStoreManager(manager) {
      this.$swal({
        background: '#FFECCD',
        color: '#ffffff',
        html: `<span style="color:white">確定要移除${manager['name']}的權限嗎？</span>`,
        icon: 'warning',
        confirmButtonText: '確定',
        showCloseButton: true,
        customClass: {
          confirmButton: 'swal-custom-rounded-button'
        }
      })
      .then(result => {
        if (!result.isConfirmed) return;
        let memberId = manager.id;
        SSORequest.delete(`${process.env.VUE_APP_TY_MANAGER_HOST}/stores/${this.storeId}/managers/${memberId}`)
        .then(response => {
          this.fetchStoreManagers();
          this.$notify({
            type: 'warning',
            text: `已移除 ${manager['name']} 的權限。`
          });
        })
        .catch(err => {
          console.error(`[STORE MANAGERS] revokeStoreManager: error`, err);
        })
        .finally(() => {
          this.dialog.revoke.show = false;
        });
      });
      
    },

    showDialogEditRole(manager) {
      this.dialog.edit.data = manager;
      this.dialog.edit.show = true;
    },
    showDialogInvite() {
      this.dialog.invite.data = {
        roleId: 1, inviteUrl: ''
      };
      this.dialog.invite.show = true;
    },
    showDialogRevoke(manager) {
      this.dialog.revoke.data = manager;
      this.dialog.revoke.show = true;
    },
    copyInvitationLink() {
      console.log(`[STORE MANAGERS]<DEBUG> copyInvitationLink: trying to copy`, this.dialog.invite.data.inviteUrl);
      navigator.clipboard.writeText(this.dialog.invite.data.inviteUrl)
      .then(() => {
        //TODO: Alert about the successful copy.
        this.$notify({
          type: 'success',
          text: '連結已複製！'
        });
      })
      .catch(err => {
        //TODO: Alert about the failed copy.
      });
    }

  },

  data() {
    return {
      managers: [ ],
      cols: [
        { text: '人員', value: 'name', sortable: false },
        { text: '權限', value: 'role', sortable: false },
        { text: '', value: 'actions', align: 'right', sortable: false },
      ],

      dialog: {
        edit: {
          show: false,
          data: { }
        },
        invite: {
          show: false,
          data: { inviteUrl: '' }
        },
        revoke: {
          show: false,
          data: { }
        }
      },

      roleOptions: [
        { text: '管理員', value: 1 },
        { text: '帳務人員', value: 3 },
        { text: '操作人員', value: 2 }
      ]

    };
  },


  filters: {
    toRoleLiteral(roleId) {
      return {
        1: '管理員',
        3: '財務人員',
        2: '操作人員'
      }[ roleId ] || '(unknown)';
    }
  }
}
</script>

<style scoped>
.theme--dark.v-data-table {
  background-color: transparent;
}

/* Increase the margin between prepend icon and text part in those input fields */
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  margin-right: 8px !important; 
}
</style>