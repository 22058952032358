<template>
  <v-container>

    <!-- Campaign/Mission Selector -->
    <v-select filled rounded hide-details class="mb-2"
      placeholder="選擇集章卡"
      prepend-inner-icon="mdi-stamper"
      v-model="selectedStampCardId" :items="stampCardAsOptions" @change="onSelectedStampCardChanged">
    </v-select>

    <!-- Search-by-phone-number input -->
    <v-text-field filled rounded hide-details class="mb-2" ref="input-phone"
      placeholder="掃描會員碼或以電話號碼搜尋會員" clearable
      prepend-inner-icon="mdi-phone" v-model="phoneNumber"
      append-icon="mdi-send"
      @focus="changeFocusInput('phoneNumber')"
      @click:append="giveStampsByPhone">
    </v-text-field>

    <!-- QrCode Reader -->
    <QrcodeReader ref="qrcode-reader" :placeholder-text="'請先選擇集章卡'"
      @detect="onQrcodeDetected"></QrcodeReader>

    <!-- Num of stamps to give -->

    <div class="rounded-pill mt-2 pa-2" style="background: rgba(255,255,255,0.1)"
      @click="changeFocusInput('numOfStamps')">
      <v-row>
        <v-col cols="6" class="d-flex justify-center align-center">
          <span class="text-h6" :class="{ 'text-focus': focusInputField === 'numOfStamps' }">
            發放集章數量
          </span>
        </v-col>
        <v-col cols="6" class="d-flex justify-center">
          <!-- <CustomIcon :src="selectedStampCard.stampImageUrl" :height="36"></CustomIcon> -->
          <!-- <span class="text-h5">{{ numOfStamps }}</span> -->
          <input ref="input-num-of-stamps" type="number" class="centered-input" readonly
            v-model="numOfStamps" />
        </v-col>
      </v-row>
    </div>
    <!-- <div class="d-flex justify-center">
      
    </div> -->

    <!-- Numpad for entering phone number -->
    <Numpad class="mt-2"
      :confirm-disabled="!numOfStamps"
      @enter="onNumpadEntered" @confirm="onNumpadConfirmed">
    </Numpad>

    <!-- A dummy spacing at the end of list FIXME: to avoid content overlapping -->
    <!-- <div style="height: 84px;">
    </div> -->

    <!-- Dialog - check-in confirmation -->
    <v-dialog v-model="dialog.give.show">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center d-flex flex-column">
          <div class="d-flex justify-center align-center">
            <!-- <CustomIcon src="https://picsum.photos/id/326/72/72" :height="64" class="rounded-lg"></CustomIcon> -->
            <CustomIcon :src="selectedStampCard.stampImageUrl" :height="64" class="rounded-lg"></CustomIcon>
            <span class="ml-4 text-h5 font-weight-bold" style="color:#FEB435">x {{ numOfStamps }}</span>
          </div>
          
          <span class="mt-4 text-center">
            <span class="text-subtitle-1">即將發放集章給</span><br>
            <span class="text-subtitle-1 font-weight-bold">{{ member.name }} | </span>
            <span class="text-subtitle-1">{{ member.phone }}</span>
          </span>
          
        </v-card-text>
        <v-card-actions class="d-flex">
          <v-btn rounded color="secondary" class="flex-grow-1" style="color:red;"
            :disabled="dialog.give.isProcessing"
            @click="closeGiveStampDialog">
            返回修改
          </v-btn>
          <v-btn rounded color="primary" class="flex-grow-1" @click="giveStamps"
            :disabled="dialog.give.isProcessing" :loading="dialog.give.isProcessing">
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import QrcodeReader from '@/components/QrcodeReader.vue';
import Numpad from '@/components/Numpad.vue';

import SSORequest from '@/sso-request';
// import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    QrcodeReader,
    Numpad
  },

  mounted() {
    this.fetchManageableStampCards();
    // if (this.$route.query.campaignId) {
    //   this.selectedCampaignId = this.$route.query.campaignId;
    //   this.$refs['qrcode-reader'].start();
    // }
    // this.dialog.give.show = true;
  },

  methods: {
    fetchManageableStampCards() {
      SSORequest.get(
        `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/store-admins/${this.user.memberId}/stamp-cards`
      )
      .then(response => {
        console.log(`[SCANNER-STAMPS]<DEBUG> fetchManageableStampCards: got stamp cards`, response.data);
        
        //TODO: Filter only those stamp-cards active for stamp collection.
        const nowTs = moment().unix();
        this.stampCards = response.data.stampCards.filter(sc => {
          // const storeRoleId = this.$store.getters.storeRoleId(sc.storeId);
          // return [ 1, 2 ].includes(storeRoleId) && sc.collectStartTime <= nowTs && sc.collectEndTime >= nowTs;
          return true;
        });

      })
      .catch(err => {
        console.error(`[SCANNER-STAMPS] fetchManageableStampCards: error`, err);
      });
    },

    onSelectedStampCardChanged() {
      this.$refs['qrcode-reader'].start();
    },

    onNumpadEntered(n) {

      // For entering phone number
      if (this.focusInputField == 'phoneNumber') {
        if (!this.phoneNumber)//FIXME: Because click:clear will set this to null.
          this.phoneNumber = '';
        if (n == 'backspace') {
          if (this.phoneNumber.length)
            this.phoneNumber = this.phoneNumber.substring(0, this.phoneNumber.length - 1);
        }
        else {
          if (this.phoneNumber.length < 10)
            this.phoneNumber += n;
        }
      }
      // For entering num-of-stamps
      else {
        if (n == 'backspace') {
          this.numOfStamps = Math.floor(this.numOfStamps / 10);
        }
        else {
          if (this.numOfStamps == 0)
            this.numOfStamps = n;
          else if (this.numOfStamps < 1000)//NOTE: The allowed value is maximally 9999.
            this.numOfStamps = this.numOfStamps * 10 + n;
        }
      }
      
    },
    onNumpadConfirmed() {
      this.giveStampsByPhone();
    },

    onQrcodeDetected(raw) {
      console.warn(`[SCANNER-STAMPS] onQrcodeDetected`, raw);

      // FIXME: Temporarily pause the camera scanning.
      this.$refs['qrcode-reader'].pause();

      // Obtain member ID for the given one-time code.
      console.warn(`[SCANNER-STAMPS] onQrcodeDetected: will look for member data by code ${raw}...`);
      let member;
      SSORequest.get(
        `${process.env.VUE_APP_TY_MANAGER_HOST}/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/member-code/${raw}`
      )
      .then(response => {
        console.log(`[SCANNER-STAMPS] onQrcodeDetected: got member data`, response.data);
        this.member = response.data;
        
        // 如果沒輸入集章數量，擋住
        if (!this.numOfStamps) {
          this.$notify({ type: 'error', text: '必須先輸入欲發給的集章數量！' });
          this.$refs['qrcode-reader'].start();//Resume the camera.
          return;
        }
        this.dialog.give.show = true;
      })
      .catch(err => {
        console.error(`[SCANNER-STAMPS] submitCode: failed`, err);
        this.$notify({
          type: 'error',
          text: '找不到會員。QR Code正確嗎？'
        });
        //Resume it after a short delay.  
        setTimeout(() => {
          this.$refs['qrcode-reader'].start();
        }, 1000);
      });
    },

    giveStampsByPhone() {
      if (!this.selectedStampCardId) {
        this.$notify({
          type: 'error', text: '請先選擇集章卡！'
        });
        return;
      }
      if (!this.phoneNumber.length) {
        // this.changeFocusInput('phoneNumber');
        this.$notify({
          type: 'error', text: '請輸入電話號碼或掃描會員碼！'
        });
        return;
      }
      if (!this.phoneNumber.match(new RegExp('\\d{10}', 'g'))) {//NOTE: 限制用numpad輸入，所以應該不會有這問題
        // this.changeFocusInput('phoneNumber');
        this.$notify({
          type: 'error', text: '電話號碼格式有誤！'
        });
        return;
      }
      if (!this.numOfStamps) {//NOTE: 先設定給章數，才給查電話號碼。
        // this.changeFocusInput('numOfStamps');
        this.$notify({
          type: 'error', text: '必須先輸入欲發給的集章數量！'
        });
        return;
      }

      SSORequest.post(
        `${process.env.VUE_APP_TY_IDENTITIES_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/members/search-by-phone`,
        { phone: this.phoneNumber }
      )
      .then(response => {
        this.member = {
          id: response.data.memberId,
          name: response.data.name,
          phone: this.phoneNumber
        };

        // 發起確認給章
        this.dialog.give.show = true;
        this.phoneNumber = '';
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: `查無此會員！（${this.phoneNumber}）`
        });
      })
    },
    
    giveStamps() {
      const member = this.member;
      const numOfStamps = this.numOfStamps;
      if (!numOfStamps) {
        this.$notify({
          type: 'error', text: '必須先輸入欲發給的集章數量！'
        });
        this.$refs['qrcode-reader'].start();
        return;
      }

      console.log(`[SCANNER-STAMPS] giveStamps: will give ${numOfStamps}-stamps to member`, member);
      const sc = this.selectedStampCard;
      this.dialog.give.isProcessing = true;
      
      return SSORequest.post(
        `${process.env.VUE_APP_TY_STAMPS_HOST}/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}`
        + `/stores/${sc.storeId}/stamp-cards/${sc.stampCardId}/award-stamps`,
        { memberId: member['id'], amount: numOfStamps }
      )
      .then(response => {
        console.log(`[SCANNER-STAMPS] giveStamps: succeeded`, response.data);
        
        
        this.$notify({
          type: 'success', text: `已發給 ${member.name} ${numOfStamps}個集章。`
        });
      })
      .catch(err => {
        console.error(`[SCANNER-STAMPS] giveStamps: failed`, err.response);
        throw err;
      })
      .finally(() => {
        this.dialog.give.isProcessing = false;
        this.closeGiveStampDialog();
      });
    },

    closeGiveStampDialog() {
      this.$refs['qrcode-reader'].start();
      this.dialog.give.show = false;
    },

    changeFocusInput(field) {
      console.log(`[SCANNER-STAMPS]<DBUEG> changeFocusInput`, field);
      this.focusInputField = field;
    }

  },

  data() {
    return {
      // Scopes
      stampCards: [ ],
      selectedStampCardId: null,

      // Search
      phoneNumber: '',
      member: { },

      // Num of stamps
      numOfStamps: 0,

      // Numpad focus
      focusInputField: 'numOfStamps',

      dialog: {
        give: {
          show: false,
          isProcessing: false,
          title: '已發放集章',
        }
      }
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    selectedStampCard() {console.log(this.selectedStampCardId);
      const ret = this.stampCards.find(sc => sc.stampCardId === this.selectedStampCardId);
      return ret || { };
    },

    storesAsOptions() {
      let stores = this.$store.getters.stores;
      return stores.map(store => {
        return {
          text: store['name'],
          value: `${store['id']}` 
        };
      });
    },
    selectedStore() {
      if (!this.storeId)
        return { };
      return this.$store.getters.store(this.storeId);
    },

    stampCardAsOptions() {
      return this.stampCards.map(sc => {
        return {
          text: `${sc.stampCardName} - ${sc.storeName}`,
          value: sc.stampCardId
        };
      })
    }
  }
}
</script>

<style scoped>
/* .v-card {
  background: transparent !important;
} */

input.centered-input {
  text-align: center;
  font-size: 1.5em;
  color: white;
  border: none;
  outline-width: 0;
}

.text-focus {
  color: #FEB435;
}
</style>