<template>
  <v-app>
    <!-- <v-app-bar app elevation="4" absolute>App Bar</v-app-bar> -->
    <v-app-bar app flat absolute color="transparent" height="56px">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-btn icon v-show="$route.meta.showGoBack" @click="goBack()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-app-bar-title class="text-h5">
        <!-- {{ $t($route.name) }} -->
        {{ appBarTitle }}
      </v-app-bar-title>
      <v-spacer></v-spacer>

      <!-- Top Right Corner Actions -->
      <template>
        <v-btn icon v-if="$route.meta.appBarIconBtn" @click="onAppBarActionClick">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn text color="primary" small v-else-if="$route.meta.appBarTextBtn" @click="onAppBarActionClick">
          {{ $route.meta.appBarTextBtnLabel }}
        </v-btn>
        <v-badge color="red" offset-x="16" offset-y="24" v-else-if="shouldShowNotiButton"
          :content="unreadNotiCount" :value="unreadNotiCount > 0">
          <v-btn icon color="primary" small
            class="ma-2"
            @click="$router.push('/admin/notifications')">
            <CustomIcon :src="require('@/assets/icons/notice.svg')" :height="24"></CustomIcon>
          </v-btn>
        </v-badge>
        <!-- <v-btn icon style="visibility:hidden;" v-else-if="$route.meta.showGoBack">
           <v-icon>mdi-heart</v-icon>
        </v-btn> -->
      </template>

      <!-- A dummy for spacing -->
      <!-- <v-btn icon :class="{
        'visibility-hidden': $route.meta.showGoBack,
        'd-none': !$route.meta.showGoBack,
      }">
        <v-icon>mdi-heart</v-icon>
      </v-btn> -->

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <!-- TABBED FOOTER NAVIGATION -->
    <v-bottom-navigation fixed grow dark v-model="tab" v-if="$route.meta.hideButtomNav !== true"
      height="72px" color="white" background-color="#FEB435"
      @change="changeRoute">

      <v-btn value="stores" color="transparent">
        <span class="mt-1">特店</span>
        <template>
          <CustomIcon :src="require('@/assets/icons/store.svg')" :height="27" v-if="tab != 'stores'"></CustomIcon>
          <CustomIcon :src="require('@/assets/icons/store-a.svg')" :height="27" v-else></CustomIcon>
        </template>
      </v-btn>

      <v-btn value="campaigns" color="transparent">
        <span class="mt-1">活動</span>
        <template>
          <CustomIcon :src="require('@/assets/icons/activity.svg')" :height="27" v-if="tab != 'campaigns'"></CustomIcon>
          <CustomIcon :src="require('@/assets/icons/activity-a.svg')" :height="27" v-else></CustomIcon>
        </template>
      </v-btn>

      <v-btn value="scanner-checkin" color="transparent">
        <span class="mt-1">報到掃碼</span>
        <template>
          <CustomIcon :src="require('@/assets/icons/scan.svg')" :height="27" v-if="tab != 'scanner-checkin'"></CustomIcon>
          <CustomIcon :src="require('@/assets/icons/scan.svg')" :height="27" v-else></CustomIcon>
        </template>
      </v-btn>

      <v-btn value="scanner-stamps" color="transparent">
        <span class="mt-1">集章發放</span>
        <template>
          <CustomIcon :src="require('@/assets/icons/stamp.svg')" :height="27" v-if="tab != 'scanner-stamps'"></CustomIcon>
          <CustomIcon :src="require('@/assets/icons/stamp-w.svg')" :height="27" v-else></CustomIcon>
        </template>
      </v-btn>

      <v-btn value="account" color="transparent">
        <span class="mt-1">會員</span>
        <template>
          <CustomIcon :src="require('@/assets/icons/user.svg')" :height="27" v-if="tab != 'account'"></CustomIcon>
          <CustomIcon :src="require('@/assets/icons/user-a.svg')" :height="27" v-else></CustomIcon>
        </template>
      </v-btn>
    </v-bottom-navigation>

    <notifications position="bottom center"
      classes="vue-notification text-subtitle-1"
      style="margin-bottom:72px;" />

    <notifications group="noti-popup" position="top center" :max="3"
      width="96%" :duration="5000"
      >
      <template slot="body" slot-scope="props">
        <div class="custom-template">
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{props.item.title}}
            </div>
            <div class="custom-template-text"
              v-html="props.item.text">
            </div>
          </div>
          <!-- <div class="custom-template-close"
               @click="props.close">
          </div> -->
          <div class="custom-template-icon d-flex align-center" @click="props.close">
            <CustomIcon :src="require('@/assets/icons/close.svg')"></CustomIcon>
          </div>
        </div>
      </template>
    </notifications>

  </v-app>
</template>

<script>
import EventBus from '@/event-bus';

export default {

  created() {
    this.$store.dispatch('signIn')
    .then(() => {
      this.initApp();
      // this.$router.push('/admin');
    })
    .catch(err => {
      console.error(`[BASE] Failed to login`, err);
    });
  },

  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed.
    // This component being reused (by using an explicit `key`) in the new route or not doesn't change anything.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused (unless you provided a `key` to `<router-view>`), and this hook will be called when that happens.
    // has access to `this` component instance.
    console.warn(`[BASE]<DEBUG> beforeRouteUpdate`, from, to);

    // Check meta to see the the next route is authorized for the current user role.
    //FIXME: For now we only take care of store-admin roles.
    //TODO: Should also consider campaign-admin roles.
    const storeId = to.params.storeId;
    if (storeId) {
      const authRoles = to.meta.authRoles || [ 1, 2, 3 ];
      const currentRole = this.$store.getters.storeRoleId(storeId);
      console.warn(`[BASE] beforeRouteUpdate: checking store role for member ${this.user.memberId} at store ${storeId}.\n`
        + `Auth-roles are ${authRoles}, current role ID is ${currentRole}`);
      if (!authRoles.includes(currentRole)) {
        // Show alert of authorization block.
        this.$notify({
          type: 'error',
          text: `權限不足！請聯絡特店管理員。`
        });
        return;
      }
    }

    // Highlight the corresponding bottom-nav button.
    if (to.path.indexOf('/admin/stores') == 0)
      this.tab = 'stores';
    else if (to.path.indexOf('/admin/campaigns') == 0)
      this.tab = 'campaigns';
    else if (to.path.indexOf('/admin/scanner-checkin') == 0)
      this.tab = 'scanner-checkin';
    else if (to.path.indexOf('/admin/scanner-stamps') == 0)
      this.tab = 'scanner-stamps';
    else if (to.path.indexOf('/admin/account') == 0)
      this.tab = 'account';

    next();
  },

  methods: {

    initApp() {
      // Fetch stores of the current user (member).
      this.$store.dispatch('initStoresData')
      .then(storeMap => {
        EventBus.$emit('init-stores-data-ready');
      });
    },

    changeRoute() {
      // // Block some WIP.
      // if (this.tab == 'scanner-stamps') {
      //   this.$notify({
      //     type: 'warning', text: '功能尚未開放。'
      //   });
      //   return;
      // }

      let dest = {
        'stores': '/admin/stores',
        'campaigns': '/admin/campaigns',
        // 'notifications': '/admin/notifications',
        'scanner-checkin': '/admin/scanner-checkin',
        'scanner-stamps': '/admin/scanner-stamps',
        'account': '/admin/account',
      }[ this.tab ];
      this.$router.push(dest);
    },

    goBack() {
      if (this.$route.meta.goBackTo)
        this.$router.replace({ name: this.$route.meta.goBackTo });
      else
        this.$router.back();
    },
    onAppBarActionClick(evt) {
      EventBus.$emit('app-bar-action-click');
    }
  },

  data() {
    return {
      tab: null
    };
  },

  computed: {

    appBarTitle() {
      let meta = this.$route.meta;
      let query = this.$route.query;
      let params = this.$route.params;
      console.log(`[BASE]<DEBUG> appBarTitle: meta`, meta);
      if (meta.titleUseStoreName === true && query.storeId) {
        let store = this.$store.getters.store(query.storeId) || { };
        if (store.name)
          return store.name;
      }
      else if (meta.titleUseCampaignName === true && params.campaignId) {
        return query.campaignName;
      }
      else if (meta.titleAppendTextByQuery) {
        let [ k, text ] = meta.titleAppendTextByQuery.split(':');
        if (query[ k ]) {
          return this.$t(this.$route.name) + text;
        }
      }
      return this.$t(this.$route.name);
    },

    user() {
      return this.$store.getters.user;
    },

    unreadNotiCount() {
      return this.$store.getters.unreadNotiCount;
    },

    shouldShowNotiButton() {
      return [
        'store-list', 'campaign-list', 'account'
      ].includes(this.$route.name);
    }

  }
}
</script>

<style>
.v-application--wrap {
  background-image: url('@/assets/img/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.v-main {
  /* padding: 56px 0px 72px 0px !important; */
}
.v-main__wrap {
  /* height: calc(100vh - 72px - 56px); */
  height: calc(100vh - 56px);
  overflow-y: scroll;
  padding-bottom: 72px;
}

.container {
  /* height: 100%; */
  /* overflow-y: scroll;
  padding-bottom: 72px; */
}
/* .theme--dark.v-data-table {
  background-color: transparent;
} */

</style>

<style lang="scss">

.custom-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  &, & > div {
    box-sizing: border-box;
  }
  background: #333333;
  border: 1px solid #454545;
  border-radius: 16px;
  .custom-template-icon {
    width: 10%;
    flex: 0 1 auto;
    // color: #15C371;
    font-size: 32px;
    padding: 0 10px;
  }
  // .custom-template-close {
  //   color:#495061;
  //   flex: 0 1 auto;
  //   padding: 0 20px;
  //   font-size: 16px;
  //   opacity: 0.2;
  //   cursor: pointer;
  //   &:hover {
  //     opacity: 0.8;
  //   }
  // }
  .custom-template-content {
    width: 90%;
    padding: 10px;
    flex: 1 0 auto;
    color: white;
    .custom-template-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      // word-wrap: break-word;
      // color: #5CB8B2;
    }
    .custom-template-text {
      font-size: 16px;
    }
  }
}
</style>

<style scoped>

.visibility-hidden {
  visibility: hidden;
}


/* Bottom nav (footer) styles */
/* NOTE: A workaround that fixes the issue where buttons don't vertically centered. */
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit;
}
.v-btn--active:hover::before {
  opacity: 0 !important;
}
.v-btn--active .v-btn__content > img {
  filter: drop-shadow(0px 0px 5px #FEB435) drop-shadow(0px 0px 5px #FEB435) drop-shadow(0px 0px 5px #FEB435);
}
.v-btn--active .v-btn__content > span {
  filter: drop-shadow(0px 0px 5px #FEB435) drop-shadow(0px 0px 5px #FEB435) drop-shadow(0px 0px 5px #FEB435);
}

</style>